<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      elevation="2"
      type="article, divider, list-item-avatar, list-item-avatar, list-item-avatar"
    ></v-skeleton-loader>
    <v-card
      v-else
      :min-width="minWidth"
      :max-width="maxWidth"
      :outlined="outlined"
    >
      <v-card-title>
        <span>{{ event.title }}</span>
        <v-spacer></v-spacer>
        <v-chip
          v-if="event.config.responses.areLocked"
          color="purple lighten-4"
          small
          class="font-weight-medium purple--text text--darken-4"
          ><v-icon small left>mdi-lock</v-icon>Rückmeldungen gesperrt</v-chip
        >
        <v-btn
          v-if="showCloseButton"
          :color="$vuetify.theme.dark ? '' : 'secondary'"
          icon
          @click="$emit('closeEvent')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle>
        Rückmeldefrist:
        {{ getDeadline(event.config.responses.deadline, true) }}
        Uhr
        <br />
        <v-chip
          v-if="deadlineHasExpired"
          small
          class="mt-2 mr-2 font-weight-medium red--text text--darken-4"
          color="red lighten-4"
          ><v-icon small left>mdi-clock-remove</v-icon>Frist abgelaufen</v-chip
        >
        <v-chip
          v-if="event.config.attendance.isMandatory"
          small
          class="mt-2 mr-2 font-weight-medium red--text text--darken-4"
          color="red lighten-4"
          ><v-icon small left>mdi-alert-circle</v-icon
          >Anwesenheitspflicht</v-chip
        >
        <v-chip
          v-if="event.config.responses.areBinding"
          small
          class="mt-2 mr-2 font-weight-medium red--text text--darken-4"
          color="red lighten-4"
          ><v-icon small left>mdi-check-decagram</v-icon>Rückmeldungen
          verbindlich</v-chip
        >
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text>
        <div :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : ''">
          <v-badge
            bordered
            overlap
            bottom
            :color="statusColor(response.status.id)"
            :icon="statusIcon(response.status.id)"
            class="mr-6"
            :class="$vuetify.breakpoint.smAndDown ? 'mb-2' : ''"
          >
            <template v-slot:badge
              ><v-icon small class="mb-0" dark>
                {{ statusIcon(response.status.id) }}
              </v-icon></template
            >
            <v-avatar
              rounded
              size="38"
              :color="
                $vuetify.theme.dark
                  ? 'rgba(255, 255, 255, 0.12)'
                  : 'grey lighten-2'
              "
              class="font-weight-medium"
              >{{ initials(user.user.displayName) }}</v-avatar
            >
          </v-badge>
          <span style="text-align: start">{{ user.user.displayName }} </span>
        </div>
        <div v-if="response.comment" class="mt-3 mb-2 ml-4">
          <v-icon small class="mr-3">mdi-comment-text</v-icon
          >{{ response.comment }}
        </div>
        <div v-if="response.guests.count !== 0" class="mt-3 mb-2 ml-4">
          <v-icon small class="mr-3">mdi-account-group</v-icon>+
          {{ response.guests.count }}
          {{ guestText(response.guests.count) }}
        </div>
        <div v-if="response.assignedTasks.length > 0" class="mt-3 mb-2 ml-4">
          <v-icon small class="mr-3">mdi-clipboard-list</v-icon>
          <v-chip
            v-for="(task, index) in response.assignedTasks"
            :key="index"
            small
            label
            outlined
            class="font-weight-medium mr-1"
            ><v-icon v-if="task.icon" small left>mdi-{{ task.icon }}</v-icon
            >{{ task.title }}</v-chip
          >
        </div>
        <div
          class="mt-3 mb-2 ml-4"
          v-if="
            response.isAccepted ||
            response.isCanceled ||
            response.assignedPosition.custom.text ||
            response.assignedPosition.comboId
          "
        >
          <v-chip
            v-if="response.isAccepted"
            label
            small
            class="font-weight-medium mr-2 orange--text text--darken-4"
            color="orange lighten-4"
            ><v-icon left small>mdi-star</v-icon>Eingeteilt</v-chip
          >
          <v-chip
            v-if="response.isCanceled"
            color="red lighten-4"
            class="font-weight-medium red--text text--darken-4"
            label
            small
            >Kurzfristige Abmeldung</v-chip
          >
          <v-chip
            v-if="response.assignedPosition.comboId"
            label
            small
            class="font-weight-medium mr-2"
            ><v-icon left small>mdi-fire-truck</v-icon
            >{{ response.assignedPosition.unit.title }} –
            {{ response.assignedPosition.position.shortTitle }}</v-chip
          >
          <v-chip
            v-if="response.assignedPosition.custom.text"
            label
            small
            class="font-weight-medium"
          >
            {{ response.assignedPosition.custom.text }}
          </v-chip>
        </div>
        <div
          v-if="
            response.type === 'automatedFromLeave' ||
            response.type === 'copiedFromRequest'
          "
          class="mt-3 mb-2 ml-4"
        >
          <v-chip
            color="light-blue lighten-4"
            label
            small
            class="font-weight-medium light-blue--text text--darken-4"
            >{{
              response.type === "automatedFromLeave"
                ? "Automatisierte Rückmeldung"
                : "Übernommen aus Abfrage"
            }}</v-chip
          >
        </div>
      </v-card-text>
      <v-card-actions v-if="showDetailsButton"
        ><v-spacer></v-spacer
        ><v-btn
          small
          depressed
          :to="{
            name: 'scheduling-event-details-tab-general',
            params: { itemId: event.meta.id },
          }"
          ><v-icon small class="mr-1">mdi-information-outline</v-icon
          >Details</v-btn
        ></v-card-actions
      >
    </v-card>
  </div>
</template>

<script>
import { auth } from "@/firebase";
// import DataChip from "@/components/_systemwide/DataChip.vue";
export default {
  name: "event-response-details-card",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      required: false,
      default: undefined,
    },
    maxWidth: {
      required: false,
      default: undefined,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    showDetailsButton: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
    },
    response: {
      type: Object,
    },
    event: {
      type: Object,
    },
  },
  components: {},
  methods: {
    timestampConverter(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
    getDeadline(deadline, withFormat) {
      if (deadline.isCustom) {
        // custom deadline
        if (withFormat) {
          return this.timestampConverter(deadline.timestamp);
        } else {
          return deadline.timestamp.toDate();
        }
      } else {
        // global deadline

        var durationInMin = this.schedulingConfig.defaultDeadlineInMinutes;
        //var durationInMin = 60;
        var MS_PER_MINUTE = 60000;
        var enddate = this.event.duration.start.timestampToDate;
        var calc = new Date(enddate - durationInMin * MS_PER_MINUTE);

        // V2: also working
        // var startdate = new Date(enddate);
        // const output = new Date(startdate.setUTCMinutes(enddate.getUTCMinutes() - durationInMin));

        if (withFormat) {
          // return this.timestampConverter(calc);
          const options = {
            weekday: "short",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          };
          return calc.toLocaleDateString("de-DE", options);
        } else {
          return calc;
        }
      }
    },
    guestText(item) {
      if (item > 1) {
        return "Gäste";
      }
      return "Gast";
    },
    initials(fullName) {
      const namesArray = fullName.trim().split(" ");
      if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
      return `${namesArray[0].charAt(0)}${namesArray[
        namesArray.length - 1
      ].charAt(0)}`;
    },
    statusIcon(item) {
      switch (item) {
        case "-1":
          return "mdi-cancel";
        case "0":
          return "mdi-help";
        case "1":
          return "mdi-check";
        case "2":
          return "mdi-close";
        case "3":
          return "mdi-minus";
        case "4":
          return "mdi-airplane";
        case "":
          return "mdi-alert-outline";
        default:
          return "";
      }
    },
    statusColor(item) {
      switch (item) {
        case "-1":
          return "purple darken-2";
        case "0":
          return "blue-grey darken-2";
        case "1":
          return "success";
        case "2":
          return "error";
        case "3":
          return "warning";
        case "4":
          return "info";
        case "":
          return "blue-grey darken-2";
        default:
          return "";
      }
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    schedulingConfig() {
      return this.$store.state.organization.activeOrganization.config
        .scheduling;
    },
    responseIsBinding() {
      var eventResponsesAreBinding = this.event.config.responses.areBinding;
      if (eventResponsesAreBinding && this.response.status.id !== "") {
        return true;
      } else {
        return false;
      }
    },
    lastChangeWasByOtherUser() {
      var lastUpdatedBy = this.response.meta.lastUpdatedBy;
      if (
        lastUpdatedBy == null ||
        lastUpdatedBy == "" ||
        lastUpdatedBy === auth.currentUser.uid
      ) {
        return false;
      } else {
        return true;
      }
    },
    deadlineHasExpired() {
      if (this.event) {
        var deadline = this.getDeadline(
          this.event.config.responses.deadline,
          false
        );
        var now = new Date();

        if (now.getTime() > deadline.getTime()) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    eventHasStarted() {
      if (new Date().getTime() > this.event.duration.start.timestampToDate) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
