<template>
  <div>
    <NavigationBar titel="Teamplaner" dense isPreview></NavigationBar>
    <section v-if="!helpers.initialLoading" class="mt-2 mx-2">
      <v-card v-if="events.length" height="100%">
        <div class="table-container">
          <table class="table-layout">
            <thead>
              <tr>
                <th class="team-header first-column transparent"></th>
                <th
                  v-for="event in events"
                  :key="event.id"
                  class="event-header"
                >
                  <teamplanner-event-card
                    :event="event"
                  ></teamplanner-event-card>
                </th>
                <th class="event-header">
                  <v-card
                    flat
                    height="100%"
                    class="d-flex align-center justify-center"
                    ><v-card-text
                      ><v-btn
                        outlined
                        text
                        :loading="helpers.nextLoading"
                        @click="loadNextEvents()"
                        >Nächste <v-icon right>mdi-chevron-right</v-icon></v-btn
                      ></v-card-text
                    ></v-card
                  >
                </th>
              </tr>
            </thead>
            <tbody>
              <!-- units -->
              <!-- tasks -->

              <!-- responses for each user -->

              <!-- responses -->
              <template v-for="userGroup in rowGroups">
                <!-- header for each user group -->
                <tr class="v-row-group__header role-header" :key="userGroup.id">
                  <td class="first-column role-name">
                    <teamplanner-group-header-card
                      :title="userGroup.title"
                      :isOpen="isGroupOpen(userGroup.id)"
                      :groupCount="sortedTeamMembers(userGroup).length"
                      @toggle="toggleGroup(userGroup.id)"
                    >
                    </teamplanner-group-header-card>
                  </td>
                </tr>

                <!-- responses for each user -->
                <template v-if="isGroupOpen(userGroup.id)">
                  <!-- user details -->
                  <tr
                    v-for="user in sortedTeamMembers(userGroup)"
                    :key="user.user.uid"
                    class="member-row"
                  >
                    <td class="first-column member-name">
                      <teamplanner-person-card
                        :user="user"
                      ></teamplanner-person-card>
                    </td>

                    <!-- response for each event -->
                    <td
                      v-for="event in events"
                      :key="event.id"
                      class="response-status"
                    >
                      <teamplanner-response-status-card
                        :response="getResponse(event, user)"
                        :event="event"
                        :user="user"
                      ></teamplanner-response-status-card>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
            <tfoot>
              <!-- responses summary -->
              <tr>
                <td
                  class="sum-name"
                  :class="
                    $vuetify.theme.dark
                      ? ['grey darken-2', 'border-dark']
                      : ['grey lighten-3', 'border-light']
                  "
                >
                  <v-card
                    flat
                    height="100%"
                    class="d-block align-center text-truncate transparent"
                  >
                    <v-list-item dense class="px-2">
                      <v-icon class="ml-2">mdi-sigma </v-icon>
                      <span class="font-weight-medium ml-2 text-truncate"
                        >Summe</span
                      >
                    </v-list-item>
                  </v-card>
                </td>

                <!-- responses summary for each event -->
                <td
                  v-for="event in events"
                  :key="event.id"
                  class="response-status"
                >
                  <teamplanner-responses-summary-card
                    :count="getSummaryCount(event)"
                  ></teamplanner-responses-summary-card>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </v-card>
      <v-card v-else>
        <v-card-text class="d-flex justify-center align-center">
          <span class="font-weight-medium"
            >Keine Veranstaltungen vorhanden</span
          >
        </v-card-text>
      </v-card>
    </section>
    <Loading v-else overlay></Loading>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db, Timestamp } from "@/firebase";
import { ADMIN } from "@/store/modules.js";
import { GET_USER_LIST } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Loading from "@/components/_system/helpers/Loading.vue";
import TeamplannerEventCard from "@/components/scheduling/TeamplannerEventCard.vue";
import TeamplannerPersonCard from "@/components/scheduling/TeamplannerPersonCard.vue";
import TeamplannerResponsesSummaryCard from "@/components/scheduling/TeamplannerResponsesSummaryCard.vue";
import TeamplannerResponseStatusCard from "@/components/scheduling/TeamplannerResponseStatusCard.vue";
import TeamplannerGroupHeaderCard from "@/components/scheduling/TeamplannerGroupHeaderCard.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";

export default {
  name: "scheduling-teamplanner",
  components: {
    NavigationBar,
    TeamplannerEventCard,
    TeamplannerPersonCard,
    TeamplannerResponseStatusCard,
    TeamplannerResponsesSummaryCard,
    TeamplannerGroupHeaderCard,
    Loading,
    SupportTools,
  },
  data() {
    return {
      helpers: {
        initialLoading: true,
        nextLoading: false,
      },

      groupOpenStatus: {},
      events: [],
      lastVisible: null,
    };
  },
  computed: {
    memberships() {
      return this.$store.state.admin.membershipsPublic;
    },
    // membershipsPrepared() {
    //   var range = {
    //     start: this.event.duration.start.timestampToDate,
    //     end: this.event.duration.end.timestampToDate,
    //   };
    //   var membershipsFiltered = this.memberships.filter((obj) => {
    //     return (
    //       new Date(obj.user.memberSinceToDate) <= new Date(range.start) && // All members that where members before or at the start of this event
    //       !(new Date(obj.user.memberUntilToDate) <= new Date(range.end)) && // All members that didn't leave before or at the event of this event
    //       obj.access.type === "member"
    //     );
    //   });

    //   var memberships = membershipsFiltered;
    //   // if (this.event.status !== "planned") {}
    //   const result = memberships.map((item) => {
    //     const container = {
    //       groupByGroup:
    //         item.group.sortKey.toString().padStart(4, "0") +
    //         "_" +
    //         item.group.title,
    //       groupByDivision:
    //         item.user.division.sortKey.toString().padStart(4, "0") +
    //         "_" +
    //         item.user.division.title,
    //       group: {
    //         title: item.group.title,
    //         sortKey: item.group.sortKey,
    //         id: item.group.id,
    //       },
    //       user: {
    //         displayName: item.user.displayName,
    //         uid: item.user.uid,
    //         // photoURL = item.user.photoURL,
    //         // position = item.user.dutyPosition,
    //       },
    //     };
    //     return container;
    //   });
    //   return result;
    // },
    rowGroups() {
      const uniqueGroups = [];
      const groupIds = new Set();

      this.memberships.forEach((member) => {
        if (!groupIds.has(member.group.id)) {
          groupIds.add(member.group.id);
          uniqueGroups.push(member.group);
        }
      });

      return uniqueGroups.sort((a, b) => a.sortKey - b.sortKey);
    },
    checkIfSupport() {
      return this.$store.getters["user/checkIfSupport"];
    },
    code() {
      return [
        { title: "events", data: this.events },
        { title: "memberships", data: this.memberships },
        { title: "rowGroups", data: this.rowGroups },
        { title: "groupOpenStatus", data: this.groupOpenStatus },
      ];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
        organizationId: this.$route.params.organizationId,
        queryIsFiltered: true,
        query: {
          type: "active",
        },
      });
      await this.getEvents();

      for (const userGroup of this.rowGroups) {
        this.groupOpenStatus[userGroup.id] = true;
      }

      setTimeout(() => {
        this.helpers.initialLoading = false;
      }, 1);
    },
    getEvents(limit = 10, startAfter = null) {
      var queryDate = new Date();
      queryDate.setHours(queryDate.getHours() - 6);
      const eventsRef = db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("events");
      console.log(Timestamp.fromDate(new Date(queryDate)));

      const eventsQueryRef = eventsRef
        .where("status", "==", "planned")
        .where(
          "duration.start.timestamp",
          ">=",
          Timestamp.fromDate(new Date(queryDate))
        );
      return eventsQueryRef
        .orderBy("duration.start.timestamp", "asc")
        .startAfter(startAfter)
        .limit(limit)
        .get()
        .then(async (querySnapshot) => {
          console.log(querySnapshot);
          this.lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
          const docs = querySnapshot.docs;
          const events = await Promise.all(
            docs.map(async (doc) => {
              const responsesRef = eventsRef
                .doc(doc.id)
                .collection("responses");
              const responsesSnapshot = await responsesRef.get();
              const event = {
                ...doc.data(),
                responses: responsesSnapshot.docs.map((responseDoc) =>
                  responseDoc.data()
                ),
              };
              return event;
            })
          );
          return this.events.push(...events);
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    loadNextEvents() {
      this.helpers.nextLoading = true;
      this.getEvents(10, this.lastVisible)
        .then(() => {
          this.helpers.nextLoading = false;
        })
        .catch(() => {
          this.helpers.nextLoading = false;
        });
    },
    toggleGroup(userGroupId) {
      this.groupOpenStatus = {
        ...this.groupOpenStatus,
        [userGroupId]: !this.groupOpenStatus[userGroupId],
      };
    },
    isGroupOpen(userGroupId) {
      return this.groupOpenStatus[userGroupId];
    },
    sortedTeamMembers(userGroup) {
      return this.memberships
        .filter((member) => member.group.id === userGroup.id)
        .sort((a, b) => a.user.displayName.localeCompare(b.user.displaName));
    },
    isUserInvited(event, user) {
      if (!event.config.attendance.isLimited) {
        return true;
      }

      const allowedAttendees = event.config.attendance.allowedAttendeesIds;

      return (
        allowedAttendees.includes("all") ||
        allowedAttendees.includes(user.user.uid) ||
        allowedAttendees.includes(user.group.id)
      );
    },
    isUserActive(event, user) {
      const eventStartTimestamp = event.duration.start.timestamp.toDate();
      const eventEndTimestamp = event.duration.end.timestamp.toDate();

      const memberSince = user.user.memberSince;

      const memberUntil = user.user.memberUntil;

      if (
        memberSince <= eventStartTimestamp &&
        memberUntil >= eventEndTimestamp
      ) {
        return false;
      }

      return true;
    },
    getResponse(event, user) {
      const emptyResponse = {
        comment: "",
        type: "regular",
        isCanceled: false,
        isAccepted: false,
        meta: {
          uid: user.user.uid,
          lastUpdatedBy: "",
        },
        status: {
          id: "0",
        },
        assignedTasks: [],
        assignedPosition: {
          custom: {
            text: "",
          },
          unit: {
            id: "",
            title: "",
          },
          position: {
            id: "",
            shortTitle: "",
          },
          comboId: "",
        },
        guests: {
          count: 0,
        },
      };

      const response = event.responses.find(
        (response) => response.meta.uid === user.user.uid
      );

      if (!this.isUserActive(event, user)) {
        emptyResponse.status.id = "-2";
      } else if (!response && !this.isUserInvited(event, user)) {
        emptyResponse.status.id = "-1";
      }

      return response ? response : emptyResponse;
    },
    getSummaryCount(event) {
      const possibleUsers = this.memberships.filter((user) => {
        return (
          this.isUserInvited(event, user) && this.isUserActive(event, user)
        );
      });
      const possibleUsersWithoutResponses = possibleUsers.filter((user) => {
        return !event.responses.find(
          (response) => response.meta.uid === user.user.uid
        );
      });

      return {
        totalResponses: event.responses.length,
        totalConfirmations: event.responses.filter(
          (response) => response.status.id === "1"
        ).length,
        totalCancellations: event.responses.filter(
          (response) => response.status.id === "2"
        ).length,
        totalIfNecessary: event.responses.filter(
          (response) => response.status.id === "3"
        ).length,
        totalNotInTown: event.responses.filter(
          (response) => response.status.id === "4"
        ).length,
        totalAwaitingResponse: possibleUsersWithoutResponses.length,
      };
    },
  },
};
</script>

<style scoped>
table {
  border-spacing: 6px;
  border-collapse: separate;
}
table > thead > tr > th {
  position: sticky;
  top: 0;
}
tbody {
  display: table-row-group;
  vertical-align: middle;
  unicode-bidi: isolate;
  border-spacing: 0;
}

tr {
  display: table-row;
  vertical-align: inherit;
  unicode-bidi: isolate;
}

.crossed::after,
.crossed::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.crossed::after {
  background: linear-gradient(
    to bottom right,
    transparent calc(50% - 1px),
    rgba(0, 0, 0, 0.12),
    transparent calc(50% + 1px)
  );
}

.crossed::before {
  background: linear-gradient(
    to top right,
    transparent calc(50% - 1px),
    rgba(0, 0, 0, 0.12),
    transparent calc(50% + 1px)
  );
}
.border-light {
  border: thin solid rgba(0, 0, 0, 0.12) !important;
}

.border-light.lighten-4 {
  border: thin solid rgba(0, 0, 0, 0.12) !important;
}

.border-dark {
  border: thin solid hsla(0, 0%, 100%, 0.12) !important;
}

.border-dark.lighten-4 {
  border: thin solid hsla(0, 0%, 100%, 0.12) !important;
}

.table-container {
  overflow-x: auto;
  display: flex;
}

.table-layout {
  table-layout: fixed;
  min-width: max-content;
}

.team-header {
  width: 225px;

  max-height: 100px;
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 2;
}

.event-header {
  width: 140px;
  max-width: 140px !important;
  position: sticky !important;
  top: 0;
}

.role-header {
  height: 30px;
}

.first-column {
  position: sticky;
  left: 0;
  max-width: 190px !important;
  width: 190px !important;
}

@media (max-width: 600px) {
  .first-column {
    max-width: 135px !important;
    width: 135px !important;
  }
}

.role-name {
  top: 0;
  border-right: none;
}

.sum-name {
  position: sticky;
  left: 0;
  top: 0;
  border-right: none;
  max-width: 190px !important;
  z-index: 2;
}

.role-colspan {
  border-left: none;
}

.task-row {
  min-height: 55px !important;
  height: 55px !important;
  max-height: 55px !important;
}

.task-cell {
  position: sticky;
  left: 0;
  max-width: 190px !important;
  z-index: 2;
}

.member-row {
  /* min-height: 55px !important;
  height: 55px !important;
  max-height: 55px !important; */
}

.member-name {
  z-index: 2;
}

.response-status {
}
</style>
