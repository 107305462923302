<template>
  <div>
    <v-card outlined @click="toggleEventDetailsDialog" height="100%">
      <v-card-title
        class="font-weight-medium text-left subtitle-2 pt-1 pb-0 px-2 d-block text-truncate"
      >
        {{ event.title }}
      </v-card-title>

      <v-card-text class="text-left px-2 pb-1">
        <data-chip
          xSmall
          :text="event.type.shortTitle"
          :color="event.type.color"
        ></data-chip>
        <br />
        <div class="text-truncate">
          <v-icon x-small left class="mr-1">mdi-calendar-start</v-icon>
          <small>{{
            timestampConverter(event.duration.start.timestamp)
          }}</small>
          <br />
          <v-icon x-small left class="mr-1">mdi-calendar-end</v-icon>
          <small>{{ timestampConverter(event.duration.end.timestamp) }}</small>
          <br />
          <v-icon x-small left class="mr-1">mdi-map-marker</v-icon
          ><small>{{ event.location.name }}</small>
        </div>
      </v-card-text>
    </v-card>
    <CustomDialog
      v-if="showEventDetailsDialog"
      @close="toggleEventDetailsDialog()"
      title="Veranstaltungsdetails"
      fullscreenOnMobile
      width="s"
      showCloseBtn
      hideCloseBtn
      hideActionBtn
    >
      <template v-slot:content>
        <br />
        <event-details-card
          :event="event"
          showDetailsButton
          outlined
        ></event-details-card>
      </template>
    </CustomDialog>
  </div>
</template>

<script>
import DataChip from "@/components/_systemwide/DataChip.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import EventDetailsCard from "@/components/scheduling/EventDetailsCard.vue";
export default {
  name: "teamplanner-event-card",
  components: { DataChip, CustomDialog, EventDetailsCard },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showEventDetailsDialog: false,
    };
  },
  methods: {
    toggleEventDetailsDialog() {
      this.showEventDetailsDialog = !this.showEventDetailsDialog;
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "short",
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>
